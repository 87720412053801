  #searchHelp {
    padding-bottom: .5vw;
  }
  
  #search {
    width: 83%;
    height: 3.5vw;
    float: left;
  }
  
  #searchButton{
    float: right;
    height: 3.5vw;
    width: 16%;
    font-size: 1.7vw;
    line-height: 0;
  }
  
  #message{
    height: 3.5vw;
  }
  
  #submit {
    height: 3.5vw;
    font-size: 1.7vw;
    line-height: 0;
    margin-left:7vw;
  }
  
  #toName {
    font-size:2vw;
  }
  
  .raleway {
    font-family: "Raleway";    
  }

  .standardSize {
    font-size: 1.7vw;
    padding-Right: 7vw;
    padding-Bottom: 2vw;
    padding-left: 7vw;
  }

  .indent{
    font-size: 2vw;
    padding-Left: 7vw;
    padding-bottom: .4vw;
  }
  
  .padLeft{
    padding-Left: .4vw;
  }
  
  .verticalAlignMiddle {
    vertical-align: middle;
  }
  
  .baseButton{
    border-style: none;
    font-family: "Raleway";
    color: black;
    font-size: 1.7vw;
    padding-right: .4vw;
    padding-left: .4vw;
    background: white;
  }
  
  .selected {
    text-decoration: underline;
  }
  
  .notSelected {
    text-decoration: none;
    color: gray;
  }
  
  .notSelected:hover {
    text-decoration: underline;
  }
  
  .selected:hover {
    background: white;
  }
  
  .baseButton:hover {
    text-decoration: underline;
  }
  
  .foundNames {
    color: gray !important;
    padding-left: 3vw !important;
  }
  
  .foundNamesHover:hover {
    color: black !important;

  }
  
  .foundbox {
    max-height: 15vw;
    overflow:auto;
    border: 1px solid #ced4da;
    padding-left: 1vw;
  }
  
  .sentInvitations {
    font-size: 1.6vw;
    padding-Right: 7vw!important;
    padding-top: 1vw!important;
  }
  
  .invitationsBox {
    max-height: 50vw;
    overflow:auto;
  }
  
  #refresh {
    margin-right:2.3vw!important;
    float:right;
  }
  
  #accept {
    background-color:#8D9EFF!important;
  }
  
  #decline {
    background-color:#FD841F!important;
  }
