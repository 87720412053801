  .bumpBtns {
    color: white!important;
    background-color:#8D9EFF!important;
    margin-top:.1vw!important;
    font-size: 1.5vw!important;
    padding: .4vw .8vw .4vw .8vw!important;
    margin-right:.4vw!important;
  }
  
  .bumpBtns:hover {
    text-decoration: underline;
  }
  
  .bumpBtns:disabled {
    background-color:#c4ccf5!important;

  }