
h2 {
  font-family: "Raleway";
  font-size: 2vw;
  text-align: left;
  display: inline;
  padding-bottom: 1vw;
}

h3 {
  font-family: "Raleway";
  font-size: .5vw;
  text-align: left;
  display: inline;
  padding-bottom: 1vw;
  padding-top: 1vw;
}

#signup {
  margin-top: 4vw;
}

 .active {
    text-decoration: underline !important;
    }
  
  .underlineWhenHovering:hover {
      text-decoration: underline !important;
      color: gray !important;
  }
 

.appName {
    font-family: "Raleway";
    font-size: 6vw;
    padding-top: 0px;
    padding-bottom: 10px;
    text-align: center;
    margin-block-start: 1vw;
    margin-block-end: 1vw;
  }

  .commands {
    background-color: white;
    border: solid lightgray;
    border-width: 0 0 .15vw 0;
    font-family: "Raleway";
    font-size: 2vw;
    margin: 0 7vw 2vw 7vw;
    padding: 0 0 .4vw 0;
    text-align: center;
    width: 85vw;
  }

  .button {
    font-family: "Raleway";
    font-size: 1.5vw;
    margin: 0 10vw 0 0;
  }

  .playerName {
    /* font-weight: 1000; */
  }

  .selected {
    background-color: #82CD47;
  }

  .selected:hover {
    background-color: #82CD47;
  }

  .xHeader {
    height: 3.5vw;
    width: 3.5vw;
    padding: .5vw;
  }

  .helptext {
    font-family: "Raleway";
    font-size: 1.7vw;
    text-align: left;
    padding: .3vw 7vw 0 3vw;
  }

  .navigation {
    font-family: "Raleway";
    font-size: 1.7vw;

  }

  .wrapper {
    padding: 0 7vw 0 7vw; 

  }

  .game {
    text-align: center;
  }

  .logo-test {
    font-weight: bold;
    transform: translateY(17%);
  }
  
  .logo-pic {
    height: 2.1vw;
    width: 2.1vw;
    margin-top: 0.7vw;
    margin-right: 0.4vw;
  }
  
  .endOfLogin{
    font-family: "Raleway";
    font-size: 1.5vw;
    text-align: left;
    padding: 3vw 7vw 0 7vw;
  }
  
  .gettingstarted {
    font-family: "Arial";
    font-weight: 900!important;
  }

  .isLoading {
    font-family: "Raleway";
    font-size: 2vw;
    opacity: 0.6;
    background-color: white;
    position:absolute;
    z-index: 1;
  }