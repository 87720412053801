.arrow {
    display: inline-block;
    height: 2.5vw;
    margin: .6vw .6vw .6vw .6vw;
    pointer-events: all;
    width: 2.5vw;
  }
  
  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  
  .left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
  
  .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  
  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  
  .purple {
    border: solid #8D9EFF;
    border-width: 0 .6vw .6vw 0;
  }
  
  .orange {
    border: solid #FD841F;
    border-width: 0 .6vw .6vw 0;
  }

  .empty:not(.centerSquare) {
    background-color:white;
    border: solid white;
    border-width: 0 .6vw .6vw 0;
    transform: rotate(45deg);
  }

  .centerSquare {
    border: solid whitesmoke;
    border-width: 0 .6vw .6vw 0;
  }
  
  .playable:hover {
    background-color: #82CD47;
    }

  .playable {
    background-color: #EAFFD7;

  }
   
  .empty {
    border: solid white;
    transform: rotate(45deg);
  }

  .winningSquare {
    background-color: #82CD47;
  }
