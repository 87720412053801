.MenuStyles {
    background-color: white;
    border-color: lightgray;
    border-style: solid;
    border-radius: .5vw;
    border-width: 1px;
    font-size: 1.3vw;
    font-family: "Raleway";
    padding: .3vw .3vw .3vw .3vw;
    position:absolute;
    top: 55px;
    z-index: 1;
}

.MenuHover:hover {
    text-decoration: underline
}

.outline {
    border-color: lightgray;
    border-style: solid;
    border-radius: .5vw;
    border-width: 1px;
}